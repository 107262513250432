import React, {useState} from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import './TokenRow.css';

const TokenRow = ({isEnabled, name, setValue, tokenUses}) => {
    const [enabled, setEnabled] = useState(isEnabled);
    const onClick = () => {
        const newEnabled = !enabled;
        setValue(name, newEnabled);
        setEnabled(newEnabled);
    }
    
    // Sanity check, should not be thrown
    if (!(name in tokenUses)) {
        return <div />;
    }

    const remaining = tokenUses[name]['remaining'];
    const total = tokenUses[name]['total'];

    return (
        <div>
            <div class="row">            
                <label class="switch">
                    <input type="checkbox" checked={enabled} value={enabled} onChange={onClick} />
                    <span class="slider round"/>
                </label>
                <div class="name">{name}</div>
            </div>
            <ProgressBar value={remaining} max={total} />
        </div>
    );
}

export default TokenRow;
import React, {useEffect, useState} from 'react';
import { fetchLogs, genLogsListener } from '../../utils/database';
import './LogTable.css';

const LOGIN_LOG = 'login';
const CHECK_IN_LOG = 'check-in';
const TOKEN_SCAN_LOG = 'token_scan';

const LogTable = () => {
    const [logs, setLogs] = useState([]);
    const [logType, setLogType] = useState('');
    const [account, setAccount] = useState('');
    const [beforeTime, setBeforeTime] = useState('');
    const [afterTime, setAfterTime] = useState('');
    const [target, setTarget] = useState('');
    const [token, setToken] = useState('');

    const updateLogs  = () => {
        fetchLogs().then(data => setLogs(data));
    };

    useEffect(()=>genLogsListener(updateLogs), []);

    const getFilteredRows = () => {
        const hasKey = (log, key) => (key in log);

        const filterInactive = (filter) => filter === ''

        const filterlogType = (log) => filterInactive(logType) || (hasKey(log, 'logType') && log['logType'] === logType); 

        const filterAccount = (log) => filterInactive(account) || (hasKey(log, 'account') && log['account'].toLowerCase().includes(account.toLowerCase())); 

        const filterBeforeTime = (log) => filterInactive(beforeTime) ||  (hasKey(log, 'time') && Date.parse(log['time']) <= Date.parse(beforeTime)); 
        
        const filterAfterTime = (log) => filterInactive(afterTime) || (hasKey(log, 'time') && Date.parse(log['time']) >= Date.parse(afterTime)); 

        const filterTarget = (log) => filterInactive(target) || (hasKey(log, 'target') && log['target'].toLowerCase().includes(target.toLowerCase())); 

        const filterToken = (log) => filterInactive(token) || (hasKey(log, 'token') && log['token'].toLowerCase().includes(token.toLowerCase()));

        return logs.filter((log) => filterlogType(log) && 
                          filterAccount(log) &&
                          filterBeforeTime(log) &&
                          filterAfterTime(log) && 
                          filterTarget(log) && 
                          filterToken(log)
                          );

    }

    const genCheckInLog = (log) => {
        const account = log['account'];
        const time = log['time'];
        const target = log['target'];
        const checkIn = log['checkIn'];
        return `${time} : Checked-${checkIn ? 'In':'Out'} ${target} by ${account}`
    }

    const genTokenScanLog = (log) => {
        const account = log['account'];
        const time = log['time'];
        const target = log['target'];
        const token = log['token'];
        const tokenChange = log['tokenChange'];
        return `${time} : Scanned ${target} for ${token}(${tokenChange}) by ${account}`
    }

    const genLoginLog = (log) => {
        const account = log['account'];
        const time = log['time'];
        return `${time} : Log-in attempt to ${account}`
    }

    const genRow = (log, index) => {
        var logMessage = null;
        switch(log['logType']) {
            case TOKEN_SCAN_LOG: 
                logMessage = genTokenScanLog(log)
                break;
            case CHECK_IN_LOG: 
                logMessage = genCheckInLog(log)
                break;
            default: 
                logMessage = genLoginLog(log);
        };
    
        const rawDataID = `data${index}`;
        const toggleDataVisible = (ID) => {
            const div = document.getElementById(ID);
            if (div.style.display === "none") {
                div.style.display = "block";
              } else {
                div.style.display = "none";
            }
        }
        return (
            <div class={log['success'] ? "logRow": "failLogRow"} key={index} id={index} onClick={()=>toggleDataVisible(rawDataID)}>
                <div style={{userSelect: 'none'}}>
                    {logMessage}
                </div>
                <div id={rawDataID} style={{display: "none"}}>
                    <pre>
                        {JSON.stringify(log, null,  2)}
                    </pre>
                </div>
            </div>
        )
    }
    
    const Rows = () => getFilteredRows().map((log, index) => genRow(log, index)).reverse();

    return (
        <div>
            <div className="filters">
                <select className="filterField" onChange={(e)=>setLogType(e.target.value)} defaultValue={''}>
                    <option value=''>Any</option>
                    <option value={LOGIN_LOG}>Login</option>
                    <option value={CHECK_IN_LOG}>CheckIn</option>
                    <option value={TOKEN_SCAN_LOG}>Token</option>
                </select>
                <input className="filterField" type="search" placeholder="User" onChange={(e)=>setTarget(e.target.value)} />
                <input className="filterField" type="search" placeholder="Scanner" onChange={(e)=>setAccount(e.target.value)} />
                <input className="filterField" type="search" placeholder="Token" onChange={(e)=>setToken(e.target.value)} />
                <label className="filterLabel">Between</label>
                <input className="filterFieldDate" type="datetime-local" onChange={(e)=>setAfterTime(e.target.value)} />
                <label className="filterLabel">and</label>
                <input className="filterFieldDate" type="datetime-local" onChange={(e)=>setBeforeTime(e.target.value)} />
            </div>
            <div className="logTable">
                <Rows />
            </div>
        </div>
    );
}

export default LogTable;
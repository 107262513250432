import React, {useEffect, useState} from 'react';
import {fetchExpectedNumberAttendees, genAttendeesListener} from '../utils/database';
import SquareData from '../components/SquareData';

const ExpectedAttendeesSq = () => {
    const [expectNoAttendees, setExpectNoAttendees] = useState(0);

    const updateNumAttendees = () => {
        fetchExpectedNumberAttendees().then(num => setExpectNoAttendees(num));
    }

    genAttendeesListener(updateNumAttendees);
    useEffect(updateNumAttendees);

    return <SquareData data={expectNoAttendees} label={"Expected Attendees"} />;
}

export default ExpectedAttendeesSq;
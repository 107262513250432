import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, onValue, set, query, orderByChild } from "firebase/database";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth"

const firebaseConfig = {
    "apiKey": "AIzaSyBzf1q9xXsS3-GYiTigzJfF9wLKpja_pf8",
    "authDomain": "ichack-nfc.firebaseapp.com",
    "projectId": "ichack-nfc",
    "storageBucket": "ichack-nfc.appspot.com",
    "messagingSenderId": "922320367364",
    "appId": "1:922320367364:web:77df21d147d072de192a51",
    "measurementId": "G-LNC19FVVX2",
    "databaseURL": "https://ichack-nfc-default-rtdb.europe-west1.firebasedatabase.app/"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);
const tokensRef = ref(database, '/tokens');
const attendeesRef = ref(database, '/attendees');
const logsRef = ref(database, '/logs');

const login = async (user, password) => {
    try {
        await signInWithEmailAndPassword(auth, user, password);
        return {success: true, error: ''}
    } catch (e) {
        return {success: false, error: e.toLocaleString()}
    }
}
    

const genAttendeesListener = (action) => {
    onValue(attendeesRef, action);
}

const genTokensListener = (action) => {
    onValue(tokensRef, action);
}

const genLogsListener = (action) => {
    onValue(logsRef, action);
}

const fetchExpectedNumberAttendees = async () => {
    return await get(attendeesRef).then((snapshot) => {
        const data = snapshot.val();
        return Object.keys(data).length;
    });
}

const fetchActiveNumberAttendees = async () => {
    return await get(attendeesRef).then((snapshot) => {
        const data = snapshot.val();
        var active_attendees = 0;
        for(var hash in data) {
            if(data[hash]["active"]) {
                active_attendees += 1;
            }
        }
        return active_attendees;
    });
}

const fetchTokenStatuses = async () => {
    return await get(tokensRef).then((snapshot) => snapshot.val())
}

const setTokenStatus = async (tokenName, isEnabled) => {
    set(ref(database, '/tokens/'+tokenName + "/enabled"), isEnabled);
}

const fetchTokenCounts = async () => {
    const snapshot = await get(ref(database));
    const all_data = snapshot.val();
    const attendee_data = all_data['attendees'];
    const token_data = all_data['tokens'];
    const token_uses = {};
    for (const token in token_data) {
        const default_token_no = token_data[token]['default_number'];
        token_uses[token] = {
            'remaining': 0,
            'total': 0,
        }
        for (const attendee in attendee_data) {
            token_uses[token]['total'] += default_token_no;
            token_uses[token]['remaining'] += attendee_data[attendee]['tokens'][token];
        }
    }
    return token_uses;
}

const fetchLogs = async () => {
    const snapshot = await get(query(logsRef, orderByChild('time')));
    if(snapshot.exists()) {
        var data = Object.values(snapshot.val());
        data.map((entry) => entry['time'] = (new Date(entry['time'])).toLocaleString());
        return data;
    }
    return [];
}

export {
    login,
    fetchExpectedNumberAttendees, 
    genAttendeesListener, 
    fetchActiveNumberAttendees,
    fetchTokenStatuses,
    genTokensListener,
    setTokenStatus,
    fetchTokenCounts,
    genLogsListener,
    fetchLogs,
};
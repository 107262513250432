import React from 'react';
import ActiveAttendeesSq from './ActiveAttendeesSq';
import ExpectedAttendeesSq from './ExpectedAttendeesSq';


const style = {
    display: "flex",
    flexDirection: "row",
}
const SquareRow = () => {
    return (
        <div style={style}>
            <ActiveAttendeesSq />
            <ExpectedAttendeesSq />
        </div>
    );
}

export default SquareRow;
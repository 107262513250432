import React, {useState} from 'react';
import {fetchActiveNumberAttendees, genAttendeesListener} from '../utils/database';
import SquareData from '../components/SquareData';

const ActiveAttendeesSq = () => {
    const [expectActiveAttendees, setExpectActiveAttendees] = useState(0);

    const updateNumAttendees = () => {
        fetchActiveNumberAttendees().then(num => setExpectActiveAttendees(num));
    }

    genAttendeesListener(updateNumAttendees);
    return <SquareData data={expectActiveAttendees}  label={"Active Attendees"} />;
}

export default ActiveAttendeesSq;
import './App.css';
import React, {useState} from 'react';
import SquareRow from './widgets/SquareRow';
import TokenTable from './widgets/TokenTable';
import LogTable from './components/LogTable/LogTable';
import { login } from './utils/database';
import { admins } from './admins';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loggedInUser, setLoggedInUser] = useState('');
  const [error, setError] = useState('');

  const tryLogin = async () => {
    const {success, error} = await login(user, password);
    console.log(success, error)
    if (success) {
      setLoggedIn(true);
      setLoggedInUser(user);
    } else {
      setError(error)
      console.log(error)
    }
  }

  const isAdmin = () => {
    return admins.includes(loggedInUser.toLowerCase())
  }

  if(!loggedIn) {
    return (
      <div className='loginPage'>
          <div className='loginBox'>
            <h2>IC Hack Admin Dash</h2>
            <h3 className="error">{error}</h3>
            <input type="email" onChange={(e)=>setUser(e.target.value)} value={user} placeholder='User' className='login'/><br/>
            <input type="password" onChange={(e)=>setPassword(e.target.value)} value={password} placeholder='Password' className='login'/><br/>
            <button className='login' onClick={tryLogin} onSubmit={tryLogin} >Login</button><br/>
          </div>
      </div>
    )
  }

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <SquareRow />
          </td>
          <td rowSpan={2}>
            <LogTable />
          </td>
        </tr>
        <tr>
          <td>
            {isAdmin() ? <TokenTable /> : <div/> }
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import TokenRow from '../components/TokenRow/TokenRow'
import { fetchTokenCounts, fetchTokenStatuses, genAttendeesListener, genTokensListener, setTokenStatus } from '../utils/database'

const TokenTable = () => {
    const [tokenData, setTokenData] = useState({})
    const [tokenUses, setTokenUses] = useState({})
    const updateTokenData = () => fetchTokenStatuses().then((data)=>setTokenData(data));
    const updateTokenUses = () => fetchTokenCounts().then((data)=>setTokenUses(data))
    
    useEffect(() => {
        updateTokenData();
        genTokensListener(()=>updateTokenData());
        updateTokenUses();
        genAttendeesListener(()=>updateTokenUses());
    }, []);

    const rows = [];
    for(const tokenName in tokenData) {
        const isEnabled = tokenData[tokenName]['enabled'];
        const key = tokenName+"-"+isEnabled;
        rows.push(<TokenRow isEnabled={isEnabled} name={tokenName} setValue={setTokenStatus} key={key} tokenUses={tokenUses} />)
    }
    return (
        <div>
            {rows}
        </div>
    )
}

export default TokenTable;
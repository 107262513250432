import React from 'react';

const style = {
    boxShadow: "3px 3px 3px 3px",
    width: "8vw",
    margin: "1vw",
    padding: "1.5vw",
    fontSize: "1.5vw",
    textAlign: "center",
    lineHeight: "4vw",
    color: "darkred",
}

const labelStyle = {
    fontSize: "0.6vw",
    lineHeight: "0.8vw",
}

const SquareData = ({data, label}) => {
    return (
        <div style={style}>
            {data}
            <br/>
            <label style={labelStyle}>
                {label}
            </label>
        </div>
    )
}

export default SquareData;
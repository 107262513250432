import React from 'react';
import './ProgressBar.css';


const ProgressBar = ({value, max}) => {

    const progressStyle =  {
        backgroundColor: 'darkorange',
        width: `${value * 100 /max}%`,
        height: '25px',
        borderRadius: '0 0 10px 10px',
        whiteSpace: 'nowrap',
        color: 'white',
        fontWeight: 'bold',
      }

    return (
        <div id="progressbar">
            <div style={progressStyle} >
                &nbsp;&nbsp;Remaining: {value} / {max}
            </div>
        </div>
    );
}

export default ProgressBar;